import React from 'react';

const Head: React.FC = () => {
  return (
    <div className="head">
      <div className="logo">
        <div className="logo-icon"></div>
        <div className="logo-shadow"></div>
      </div>
      <div className="animated-text">
        <div className="subtext">Eduard</div>
      </div>
      <div className="subheading">The Doge For You.</div>
    </div>
  );
};

export default Head;
