import React from 'react';
import '../styles/main.css';
import Slider from '../components/Slider';

const App: React.FC = () => {
  React.useEffect(() => {
    // Handle page transition effect
    const handleLoad = () => document.body.classList.remove('transition-out');
    const handleClick = (ev: MouseEvent) => {
      const link = (ev.target as HTMLElement).closest('.a-links') as HTMLAnchorElement | null;
      if (link && !link.dataset.wasCanceledBefore) {
        document.body.classList.add('transition-out');
        setTimeout(() => {
          window.location.href = link.href;
        }, 750);
        ev.preventDefault();
        link.dataset.wasCanceledBefore = 'true';
      }
    };

    window.addEventListener('load', handleLoad);
    document.body.addEventListener('click', handleClick, true);

    return () => {
      window.removeEventListener('load', handleLoad);
      document.body.removeEventListener('click', handleClick, true);
    };
  }, []);

  return (
    <div className="content">
      <Slider />
    </div>
  );
};

export default App;
